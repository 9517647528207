import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../sass/_contact-form.scss'

export default function ContactForm() {
  const [ emailSent, setEmailSent ] = useState('')

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail-kelly', 'template_ju1xify', e.target, 'user_S2Asg2P9oH9PzcLATX4Ja')
      .then((result) => {
          setEmailSent(<h1>Your email has been sent.</h1>)
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <form className="contact-form" onSubmit={sendEmail}>
      { emailSent }
      <label>Name</label>
      <input type="text" name="name" required />
      <label>Email</label>
      <input type="email" name="email" required />
      <label>Subject</label>
      <input type="text" name="subject" required />
      <label>Message</label>
      <textarea rows="10" name="message" required />
      <span>
        <input className="submit-btn" type="submit" value="Send" />
      </span>
    </form>
  );
}