import React, { useState, useEffect } from 'react'
import '../../sass/_testimonial.scss'

import { Link } from 'react-router-dom'

import { testimonials } from '../../const/testimonial'

const Testimonials = () => {
    let [ testimonial, setTestimonial ] = useState('')
    let count = 0

    useEffect(() => {
        setInterval(() => {
            count++
            if (count === testimonials.length) {
                count = 0
            }

            let idx = testimonials[count]

            setTestimonial(idx)
        }, 7000) 
    }, [])
    
        return (
            <div className="testimonials">
                <div className="testimonials__body">
                    <h1>Testimonials</h1>
                    {
                        testimonial === '' ?
                        <>
                        <div className="quote-symbol">
                            &ldquo;
                        </div>
                        <div className="quote">
                            <p className="quote">I couldn't have asked for a better agent</p>
                        </div>
                        <div className="author">
                            <p className="author">Brad B.</p>
                        </div>
                        <div className="blurb">
                            <p className="blurb">"I can't say enough great things about Kelly Griffiths and how she conducts business. Kelly went above and beyond to help us buy and sell our house over the past 4 years. She went out of her way to help us understand every step of the process, and even helped to watch our son while we had to prepare  the house for open houses and inspections. Kelly fought for us to get the house of our dreams, and helped us get well over our asking price, with multiple offers when we sold the house. She is kind, knowledgable, and patient. Kelly heard all of our requests, requirements, and desires during the process and helped to ease our mind when things undoubtedly got a bit stressful. I would recommend Kelly to anyone and everyone looking for real estate services, and feel beyond confident that Kelly will help get you what you want and won't stop until she helps you find it. Kelly made an otherwise difficult process bearable, and dare I say enjoyable. She's the best in the biz"</p>,
                        </div>
                        </>
                        :
                        <div className={testimonial.class}>
                            <div className="quote-symbol">
                                &ldquo;
                            </div>
                            <div className="quote">
                                {testimonial.quote}
                            </div>
                            <div className="author">
                                {testimonial.author}
                            </div>
                            <div className="blurb">
                                {testimonial.blurb}
                            </div>
                        </div>
                    }
                    <div className="testimonials__btn">
                        <h3><Link to="/testimonials">Read More</Link></h3>
                    </div>
                </div>
            </div>
        )
    }

export default Testimonials