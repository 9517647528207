import React from 'react'

import Nav from '../Nav/Nav'
import Slider from '../Slider/Slider'
import Testimonials from '../Testimonials/Testimonials'
import CurrentListing from '../CurrentListing/CurrentListing'
import HomeRecentSold from '../HomeRecentSold/HomeRecentSold'
import HomeAbout from '../HomeAbout/HomeAbout'
import FadeIn from '../FadeIn/FadeIn'

const Home = () => {
    return (
        <div className="home">
            <Nav />
            <Slider />
            <FadeIn>
                <HomeAbout />
            </FadeIn>
            <FadeIn>
                <Testimonials />
            </FadeIn>
            <FadeIn>
                <CurrentListing />
            </FadeIn>
            <FadeIn>
                <HomeRecentSold />
            </FadeIn>
        </div>
    )
}

export default Home