import React from 'react'
import '../../sass/_testimonial-page.scss'

import { testimonials } from '../../const/testimonial'

import SecondaryNav from '../SecondaryNav/SecondaryNav'

const TestimonialPage = () => {

    return (
        <div className="testimonial-page">
            <SecondaryNav />
            <h1>Testimonials</h1>
            <div className="testimonial-page__section">
                {
                    testimonials.map((testimonial, idx) =>
                        <div key={idx} className="testimonial-container">
                            <div className="quote-symbol">
                                &ldquo;
                            </div>
                            <div className="quote">
                                {testimonial.quote}
                            </div>
                            <div className="author">
                                {testimonial.author}
                            </div>
                            <div className="blurb">
                                {testimonial.blurb}
                            </div>
                            <hr />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TestimonialPage