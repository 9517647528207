import React, { useState } from 'react'
import '../../sass/_recent-sold.scss'

import { recentSold } from '../../const/recentSold'

import SecondaryNav from '../SecondaryNav/SecondaryNav'

const RecentSold = () => {
    const [ currentPage, setCurrentPage ] = useState(1)
    const maxItemsPerPage = 12

    const changePage = (string) => {
        if (string === 'back') {
         setCurrentPage(currentPage - 1)
         window.scrollTo({top: 0, behavior: 'smooth'});

        } else if (string === 'next') {
          setCurrentPage(currentPage + 1)
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    return (
        <div className="recent-sold">
            <SecondaryNav />
            <div className="recent-sold-inner">
                <h1>Recently Sold</h1>
                <div className="recent">
                {
                    recentSold.slice((currentPage * maxItemsPerPage) - maxItemsPerPage,
                    currentPage * maxItemsPerPage).map((recent, idx) => 
                        <div key={idx} className="recent__house">
                            <div className="recent__image">
                                <img src={recent.img} alt={recent.href} />
                            </div>
                            <div className="recent__info">
                                <div className="recent__info-address">
                                    {recent.address}
                                </div>
                                <hr />
                                <div className="recent__info-rooms">
                                    {recent.price}
                                </div>
                                {/* <hr /> */}
                                <div className="recent__info-price">
                                    {recent.specs}
                                </div>
                            </div>
                        </div>
                    )
                }
                </div>
                <div className="nav-btns">
                    <div>
                        {currentPage > 1 ?
                                <button className="back" onClick={() => changePage('back')}><span>Back</span></button>
                            : null
                        }
                    </div>
                    <div>
                        {recentSold.length -1 > currentPage * maxItemsPerPage ?
                                <button className="next" onClick={() => changePage('next')}><span>Next</span></button>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentSold