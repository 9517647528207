import React from 'react'
import '../../sass/_home-about.scss'

import SideImages from '../sideImages/SideImages'

const HomeAbout = () => {
    return (
        <div className="home-about">
            <div className="home-about__text">
                <h1>About</h1>
                <p>Born in London, I moved to Los Angeles when I was ten and have been calling it home ever since. There are so many things that endear me to the city and it's hard not to love the Southern California lifestyle.</p>
                <p>I love working with clients, guiding them through this pivotal life transition. From house hunting and introducing people to new neighborhoods, to working with vendors, assisting with remodels, and negotiating the deal, I bring my passion for and knowledge of the market as well as years of experience living in LA to each new business relationship. My greatest joy is in bonding with my clients and I’m happy to have become lifelong friends with many of them.</p>  
                <p>Outside of work I love living in Los Angeles, doting on my dog, spending time with my friends and family and taking full advantage of the many options the sunny weather offers.</p>
            </div>
            <div className="slides">
                <SideImages />
            </div>
        </div>
    )
}

export default HomeAbout