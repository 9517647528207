import React from 'react'

import caseAve from '../assets/images/recentSold/case-ave.png'
import centinela from '../assets/images/recentSold/centinela.png'
import dahliaDrive from '../assets/images/recentSold/dahlia-drive.jpg'
import lyricAve from '../assets/images/recentSold/lyric-ave.png'
import northAve47 from '../assets/images/recentSold/north-ave-47.png'
import northAve from '../assets/images/recentSold/north-ave.png'
import northCurson from '../assets/images/recentSold/north-curson.png'
import northNorman from '../assets/images/recentSold/north-norman-place.png'
import packwoodTrail from '../assets/images/recentSold/packwood-trail.jpg'
import sunnyCove from '../assets/images/recentSold/sunny-cove.jpg'
import wollam from '../assets/images/recentSold/wollam.jpg'
import wonderland from '../assets/images/recentSold/wonderland-ave.jpg'
import euclid from '../assets/images/recentSold/euclid.png'
import lucerne from '../assets/images/recentSold/lucerne.png'
import northAve63 from '../assets/images/recentSold/north-ave-63.png'
import olin from '../assets/images/recentSold/olin.png'
import montecito from '../assets/images/recentSold/montecito.png'
import midnightSky from '../assets/images/recentSold/midnight-sky.png'
import duaneStreet from '../assets/images/recentSold/duane-street.png'
import fullerAve from '../assets/images/recentSold/fuller-ave.png'
import kensington from '../assets/images/recentSold/kensington-road.png'
import west22 from '../assets/images/recentSold/west-22.png'
import emelitaSt from '../assets/images/recentSold/1113-emelita-st.png'
import occidental from '../assets/images/recentSold/1432-occidental-blvd.png'
import carmona from '../assets/images/recentSold/1455-carmona-ave.png'
import burwood from '../assets/images/recentSold/burwood-ave.png'
import kelton from '../assets/images/recentSold/kelton-ave.png'
import milwaukee from '../assets/images/recentSold/milwaukee-ave.png'
import northAve52 from '../assets/images/recentSold/440-north-ave-52.png'
import leGrey1100 from '../assets/images/recentSold/1100-le-grey-ave.png'
import lowell3709 from '../assets/images/recentSold/3709-lowell-ave.png'
import laVillaMarina4766 from '../assets/images/recentSold/4766-la-villa-marina.png'
import hollywood6250 from '../assets/images/recentSold/6250-hollywood-blvd.png'
import keswick11056 from '../assets/images/recentSold/11056-keswick-st.png'
import losPalmas815 from '../assets/images/recentSold/815-north-las-palmas.png'
import restrictedAddress1 from '../assets/images/recentSold/restricted-address-1.png'
import granada4857 from '../assets/images/recentSold/4857-granada-st.png'
import saginaw1340 from '../assets/images/recentSold/1340-saginaw-st.png'
import alta2006 from '../assets/images/recentSold/2006-alta-st.png'
import southGrandAve1100 from '../assets/images/recentSold/1100-south-grand-ave (2).png'
import southVirgil435 from '../assets/images/recentSold/435-south-virgil.png'
import northAve511 from '../assets/images/recentSold/511-north-ave-49.png'
import west9th600 from '../assets/images/recentSold/600-west-9th.png'
import laCienega1124 from '../assets/images/recentSold/1124-la-cienega.png'
import guardia4029 from '../assets/images/recentSold/4029-guardia-ave.png'
import restrictedAddress2 from '../assets/images/recentSold/restricted-address-2.png'
import terra143 from '../assets/images/recentSold/143-terra-bella.png'
import west5th312 from '../assets/images/recentSold/312-west-5th.png'
import northAve452 from '../assets/images/recentSold/452-north-ave-52.png'
import marguerite3516 from '../assets/images/recentSold/3516-marguerite.png'
import echo5036 from '../assets/images/recentSold/5036-echo.png'
import fallbrook5446 from '../assets/images/recentSold/5446-fallbrook.png'
import westMagnolia15325 from '../assets/images/recentSold/15325-west-magnolia.png'
import thirdSt2203 from '../assets/images/recentSold/2203-3rd-st.png'

export const recentSold = [
    {
        img: northNorman,
        href: 'Kelly Griffiths | 1000 North Norman Place',
        address: <><p>1000 North Norman Place</p><p>Los Angeles, CA 90049</p></>,
        price: <p>$4,075,000</p>,
        // specs: <p>4 bedrooms | 5 bathrooms | 3,441SF</p>
    },
    {
        img: wonderland,
        href: 'Kelly Griffiths | 8640 Wonderland Avenue',
        address: <><p>8640 Wonderland Avenue</p><p>Los Angeles, CA 90046</p></>,
        price: <p>$2,206,355</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 2,402SF</p>
    },
    {
        img: caseAve,
        href: 'Kelly Griffiths | 5753 Case Avenue',
        address: <><p>5753 Case Avenue</p><p>North Hollywood, CA 91601</p></>,
        price: <p>$2,095,000</p>,
        // specs: <p>12 bedrooms | 9 bathrooms | —SF</p>
    },
    {
        img: thirdSt2203,
        href: 'Kelly Griffiths | 2203 3rd Street, Unit 11',
        address: <><p>2203 3rd Street, Unit 11</p><p>Santa Monica, CA 90405</p></>,
        price: <p>$1,850,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,903SF</p>
    },
    {
        img: packwoodTrail,
        href: 'Kelly Griffiths | 7267 Packwood Trail',
        address: <><p>7267 Packwood Trail</p><p>Los Angeles, CA 90068</p></>,
        price: <p>$1,775,000</p>,
        // specs: <p>4 bedrooms | 5 bathrooms | 4262SF</p>
    },
    {
        img: dahliaDrive,
        href: 'Kelly Griffiths | 5148 Dahlia Drive',
        address: <><p>5148 Dahlia Drive</p><p>Los Angeles, CA 90041</p></>,
        price: <p>$1,495,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,795SF</p>
    },
    {
        img: northAve,
        href: 'Kelly Griffiths | 460 North Ave 56',
        address: <><p>460 North Ave 56</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$1,250,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,536SF</p>
    },
    {
        img: northAve47,
        href: 'Kelly Griffiths | 1429 North Ave 47',
        address: <><p>1429 North Ave 47</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$1,192,500</p>,
        // specs: <p>— | — | —SF</p>
    },
    {
        img: centinela,
        href: 'Kelly Griffiths | 1311 Centinela Avenue, Unit 3',
        address: <><p>1311 Centinela Avenue, Unit 3</p><p>Santa Monica, CA 90404</p></>,
        price: <p>$1,150,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,658SF</p>
    },
    {
        img: wollam,
        href: 'Kelly Griffiths | 1828 Wollam Street',
        address: <><p>1828 Wollam Street</p><p>Los Angeles, CA 90065</p></>,
        price: <p>$1,144,725</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,354SF</p>
    },
    {
        img: northCurson,
        href: 'Kelly Griffiths | 1922 North Curson Avenue',
        address: <><p>1922 North Curson Avenue</p><p>Los Angeles, CA 90046</p></>,
        price: <p>$1,015,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,538SF</p>
    },
    {
        img: lyricAve,
        href: 'Kelly Griffiths | 2215 Lyric Avenue',
        address: <><p>2215 Lyric Avenue</p><p>Los Angeles, CA 90027</p></>,
        price: <p>$949,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,536SF</p>
    },
    {
        img: sunnyCove,
        href: 'Kelly Griffiths | 857 Sunny Cove',
        address: <><p>6857 Sunny Cove</p><p>Los Angeles, CA 90068</p></>,
        price: <p>$930,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 982SF</p>
    },
    {
        img: euclid,
        href: 'Kelly Griffiths | 917 Euclid Street, Unit 4',
        address: <><p>917 Euclid Street, Unit 4</p><p>Santa Monica, CA 90403</p></>,
        price: <p>$925,500</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,651SF</p>
    },
    {
        img: lucerne,
        href: 'Kelly Griffiths | 1077 South Lucerne Boulevard',
        address: <><p>1077 South Lucerne Boulevard</p><p>Los Angeles, CA 90019</p></>,
        price: <p>$918,000</p>,
        // specs: <p>4 bedrooms | 3 bathrooms | 2,124SF</p>
    },
    {
        img: montecito,
        href: 'Kelly Griffiths | 1092 Montecito Drive',
        address: <><p>1092 Montecito Drive</p><p>Los Angeles, CA 90031</p></>,
        price: <p>$885,000</p>,
        // specs: <p>4 bedrooms | 3 bathrooms | 1,581SF</p>
    },
    {
        img: northAve63,
        href: 'Kelly Griffiths | 223 North Ave 63',
        address: <><p>223 North Ave 63</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$885,000</p>,
        // specs: <p>4 bedrooms | 2 bathrooms | 1,762SF</p>
    },
    {
        img: olin,
        href: 'Kelly Griffiths | 9044 Olin Street',
        address: <><p>9044 Olin Street</p><p>Los Angeles, CA 90034</p></>,
        price: <p>$879,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 1,210SF</p>
    },
    {
        img: midnightSky,
        href: 'Kelly Griffiths | 47 Midnight Sky',
        address: <><p>47 Midnight Sky</p><p>Irvine, CA 92620</p></>,
        price: <p>$885,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,949SF</p>
    },
    {
        img: west22,
        href: 'Kelly Griffiths | 1606 West 22nd Street',
        address: <><p>1606 West 22nd Street</p><p>San Pedro, CA 90732</p></>,
        price: <p>$852,000</p>,
        // specs: <p>4 bedrooms | 2 bathrooms | 1,735SF</p>
    },
    {
        img: duaneStreet,
        href: 'Kelly Griffiths | 1541 Duane Street',
        address: <><p>1541 Duane Street</p><p>Los Angeles, CA 90026</p></>,
        price: <p>$850,000</p>,
        // specs: <p>4 bedrooms | 2 bathrooms | 1,554SF</p>
    },
    {
        img: kensington,
        href: 'Kelly Griffiths | 755 East Kensington Road',
        address: <><p>755 East Kensington Road</p><p>Los Angeles, CA 90026</p></>,
        price: <p>$837,500</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 1,400SF</p>
    },
    {
        img: fullerAve,
        href: 'Kelly Griffiths | 1151 North Fuller Avenue, Unit 2',
        address: <><p>1151 North Fuller Avenue, Unit 2</p><p>West Hollywood, CA 90046</p></>,
        price: <p>$835,000</p>,
        // specs: <p>2 bedrooms | 3 bathrooms | 1,347SF</p>
    },
    {
        img: milwaukee,
        href: 'Kelly Griffiths | 1031 Milwaukee Avenue',
        address: <><p>1031 Milwaukee Avenue</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$828,200</p>,
        // specs: <p>3 bedrooms | 1 bathrooms | 1,652SF</p>
    },
    {
        img: burwood,
        href: 'Kelly Griffiths | 6123 Burwood Avenue',
        address: <><p>6123 Burwood Avenue</p><p>Highland Park, CA 90042</p></>,
        price: <p>$820,316</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | —SF</p>
    },
    {
        img: kelton,
        href: 'Kelly Griffiths | 448 Kelton Avenue, Unit 1',
        address: <><p>448 Kelton Avenue, Unit 1</p><p>Los Angeles, CA 90024</p></>,
        price: <p>$810,000</p>,
        // specs: <p>3 bedrooms | 3.5 bathrooms | 1,966SF</p>
    },
    {
        img: occidental,
        href: 'Kelly Griffiths | 1432 North Occidental Boulevard',
        address: <><p>1432 North Occidental Boulevard</p><p>Los Angeles, CA 90026</p></>,
        price: <p>$799,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,120SF</p>
    },
    {
        img: carmona,
        href: 'Kelly Griffiths | 1455 Carmona Avenue',
        address: <><p>1455 Carmona Avenue</p><p>Los Angeles, CA 90019</p></>,
        price: <p>$765,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 720SF</p>
    },
    {
        img: emelitaSt,
        href: 'Kelly Griffiths | 11113 Emelita Street',
        address: <><p>11113 Emelita Street</p><p>North Hollywood, CA 91601</p></>,
        price: <p>$749,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,322SF</p>
    },
    {
        img: leGrey1100,
        href: 'Kelly Griffiths | 1100 Le Gray Avenue',
        address: <><p>1100 Le Gray Avenue</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$735,000</p>,
        // specs: <p>3 bedrooms | 1 bathrooms | 1,038SF</p>
    },
    {
        img: northAve52,
        href: 'Kelly Griffiths | 440 North Avenue 52',
        address: <><p>440 North Avenue 52</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$728,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 2,026SF</p>
    },
    {
        img: laVillaMarina4766,
        href: 'Kelly Griffiths | 4766 La Villa Marina, Unit B',
        address: <><p>4766 La Villa Marina, Unit B</p><p>Marina del Rey, CA 90292</p></>,
        price: <p>$689,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,582SF</p>
    },
    {
        img: lowell3709,
        href: 'Kelly Griffiths | 3709 Lowell Avenue',
        address: <><p>3709 Lowell Avenue</p><p>Los Angeles, CA 90032</p></>,
        price: <p>$685,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,356SF</p>
    },
    {
        img: hollywood6250,
        href: 'Kelly Griffiths | 6250 Hollywood Boulevard, Unit 8H',
        address: <><p>6250 Hollywood Boulevard, Unit 8H</p><p>Los Angeles, CA 90028</p></>,
        price: <p>$680,000</p>,
        // specs: <p>1 bedrooms | 1 bathrooms | 1,220SF</p>
    },
    {
        img: keswick11056,
        href: 'Kelly Griffiths | 11056 Keswick Street',
        address: <><p>11056 Keswick Street</p><p>Sun Valley, CA 91352</p></>,
        price: <p>$675,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,448SF</p>
    },
    {
        img: losPalmas815,
        href: 'Kelly Griffiths | 815 North Las Palmas Avenue, Unit 306',
        address: <><p>815 North Las Palmas Avenue, Unit 306</p><p>Los Angeles, CA 90038</p></>,
        price: <p>$650,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,439SF</p>
    },
    {
        img: restrictedAddress1,
        href: 'Kelly Griffiths | Restricted Address',
        address: <><p>Restricted Address</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$635,000</p>,
        // specs: <p>3 bedrooms | 1 bathrooms | 1,652SF</p>
    },
    {
        img: granada4857,
        href: 'Kelly Griffiths | 4857 Granada Street',
        address: <><p>4857 Granada Street</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$630,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,044SF</p>
    },
    {
        img: saginaw1340,
        href: 'Kelly Griffiths | 1340 Saginaw Street',
        address: <><p>1340 Saginaw Street</p><p>Eagle Rock, CA 90041</p></>,
        price: <p>$614,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,189SF</p>
    },
    {
        img: alta2006,
        href: 'Kelly Griffiths | 2006 Alta Street',
        address: <><p>2006 Alta Street</p><p>Los Angeles, CA 90031</p></>,
        price: <p>$600,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,328SF</p>
    },
    {
        img: southGrandAve1100,
        href: 'Kelly Griffiths | 1100 South Grand Avenue, Unit A003',
        address: <><p>1100 South Grand Avenue, Unit A003</p><p>Los Angeles, CA 90015</p></>,
        price: <p>$590,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 1,320SF</p>
    },
    {
        img: guardia4029,
        href: 'Kelly Griffiths | 4029 Guardia Avenue',
        address: <><p>4029 Guardia Avenue</p><p>Los Angeles, CA 90032</p></>,
        price: <p>$510,000</p>,
        // specs: <p>3 bedrooms | 1 bathrooms | 1,250SF</p>
    },
    {
        img: laCienega1124,
        href: 'Kelly Griffiths | 1124 North La Cienega Boulevard, Unit 301',
        address: <><p>1124 North La Cienega Boulevard, Unit 301</p><p>West Hollywood, CA 90069</p></>,
        price: <p>$495,000</p>,
        // specs: <p>1 bedrooms | 1 bathrooms | 768SF</p>
    },
    {
        img: southVirgil435,
        href: 'Kelly Griffiths | 1435 South Virgil Avenue, Unit 222',
        address: <><p>1435 South Virgil Avenue, Unit 222</p><p>Los Angeles, CA 90020</p></>,
        price: <p>$485,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 926SF</p>
    },
    {
        img: northAve511,
        href: 'Kelly Griffiths | 511 North Ave 49',
        address: <><p>511 North Ave 49</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$475,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 888SF</p>
    },
    {
        img: west9th600,
        href: 'Kelly Griffiths | 600 West 9th Street, Unit 112',
        address: <><p>600 West 9th Street, Unit 112</p><p>Los Angeles, CA 90015</p></>,
        price: <p>$462,000</p>,
        // specs: <p>1 bedrooms | 1 bathrooms | 754SF</p>
    },
    {
        img: restrictedAddress2,
        href: 'Kelly Griffiths | Restricted Address',
        address: <><p>Restricted Address</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$459,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,067SF</p>
    },
    {
        img: northAve452,
        href: 'Kelly Griffiths | 452 North Avenue 52',
        address: <><p>452 North Avenue 52</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$435,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,164SF</p>
    },
    {
        img: westMagnolia15325,
        href: 'Kelly Griffiths | 15325 West Magnolia Boulevard, Unit 303',
        address: <><p>15325 West Magnolia Boulevard, Unit 303</p><p>Sherman Oaks, CA 91403</p></>,
        price: <p>$431,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,392SF</p>
    },
    {
        img: marguerite3516,
        href: 'Kelly Griffiths | 3516 Marguerite Street',
        address: <><p>3516 Marguerite Street</p><p>Glassell Park, CA 90065</p></>,
        price: <p>$412,500</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 935SF</p>
    },
    {
        img: fallbrook5446,
        href: 'Kelly Griffiths | 5446 Fallbrook Avenue',
        address: <><p>5446 Fallbrook Avenue</p><p>Woodland Hills, CA 91367</p></>,
        price: <p>$369,000</p>,
        // specs: <p>3 bedrooms | 2 bathrooms | 1,295SF</p>
    },
    {
        img: terra143,
        href: 'Kelly Griffiths | 14325 Terra Bella Street, Unit C',
        address: <><p>14325 Terra Bella Street, Unit C</p><p>Panorama City, CA 91402</p></>,
        price: <p>$360,000</p>,
        // specs: <p>2 bedrooms | 2 bathrooms | 1,246SF</p>
    },
    {
        img: echo5036,
        href: 'Kelly Griffiths | 5036 Echo Street, Unit 11',
        address: <><p>5036 Echo Street, Unit 11</p><p>Los Angeles, CA 90042</p></>,
        price: <p>$355,000</p>,
        // specs: <p>2 bedrooms | 1 bathrooms | 992SF</p>
    },
    {
        img: west5th312,
        href: 'Kelly Griffiths | 312 West 5th Street, Unit 1113',
        address: <><p>312 West 5th Street, Unit 1113</p><p>Los Angeles, CA 90013</p></>,
        price: <p>$180,150</p>,
        // specs: <p>1 bedrooms | 1 bathrooms | 660SF</p>
    },
]