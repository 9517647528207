import React from 'react'
import '../../sass/_home-recent-sold.scss'

import { Link } from 'react-router-dom'
 
import pic1 from '../../assets/images/homeImages/1092-monticito-drive-home.png'
import pic2 from '../../assets/images/homeImages/lowell-home.png'
import pic3 from '../../assets/images/homeImages/17432-stare-st.png'

const HomeRecentSold = () => {
    return (
        <div className="home-recent-sold">
            <h1>Recently Sold</h1>
            <div className="recent">   
                <div className="recent__house">
                    <div className="recent__image one">
                        <img src={pic1} alt='Kelly Griffiths | 1092 Montecito Drive' />
                    </div>
                    <div className="recent__info">
                        <div className="recent__info-address">
                            <p>1092 Montecito Drive</p>
                            <p>Los Angeles, CA</p>
                        </div>
                        <hr />
                        <div className="recent__info-rooms">
                            <p>$1,101,000</p>
                        </div>
                        {/* <hr />
                        <div className="recent__info-price">
                            <p>4 bedrooms | 3 bathrooms | 1,581SF</p>
                        </div> */}
                    </div>
                </div>
                <div className="recent__house">
                    <div className="recent__image two">
                        <img src={pic2} alt='Kelly Griffiths | 3709 Lowell Avenue' />
                    </div>
                    <div className="recent__info">
                        <div className="recent__info-address">
                            <p>3709 Lowell Avenue</p>
                            <p>Los Angeles, CA</p>
                        </div>
                        <hr />
                        <div className="recent__info-rooms">
                            <p>$685,000</p>
                        </div>
                        {/* <hr />
                        <div className="recent__info-price">
                            <p>3 bedrooms | 2 bathrooms | 1,356SF</p>
                        </div> */}
                    </div>
                </div>
                <div className="recent__house">
                    <div className="recent__image three">
                        <img src={pic3} alt='Kelly Griffiths | 17432 Stare St' />
                    </div>
                    <div className="recent__info">
                        <div className="recent__info-address">
                            <p>17432 Stare St</p>
                            <p>Northridge, CA</p>
                        </div>
                        <hr />
                        <div className="recent__info-rooms">
                            <p>$815,600</p>
                        </div>
                        {/* <hr />
                        <div className="recent__info-price">
                            <p>4 bedrooms | 2 bathrooms | 91325SF</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="btn-container">
                <h3><Link to="/recently-sold">See More</Link></h3>
            </div>
        </div>
    )
}

export default HomeRecentSold