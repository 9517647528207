import React from 'react'
import '../../sass/_contact.scss'
import '../../sass/_nav.scss'
import '../../sass/_secondaryNav.scss'

import SecondaryNav from '../SecondaryNav/SecondaryNav'

import ContactForm from './ContactForm'

const Contact = () => {
    return (
        <div className="contact">
            <SecondaryNav />
            <div className="contact-form">
                <h1>Please fill out the form below to get in touch.</h1>
                <ContactForm />
            </div>
        </div>
    )
}

export default Contact