import React from 'react';
import './sass/_base.scss';

import { Switch, Route } from 'react-router-dom'

// components
import Home from './components/Home/Home'
import Contact from './components/Contact/Contact'
import RecentSold from './components/RecentSold/RecentSold'
import TestimonialPage from './components/TestimonialPage/TestimonialPage'
import Footer from './components/Footer/Footer'

function App() {

  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/recently-sold" exact component={RecentSold} />
        <Route path="/testimonials" exact component={TestimonialPage} />
      </Switch>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
