import React from 'react'
import '../../sass/_side-images.scss'

import scrollImg1 from  '../../assets/images/homeImages/keswick.jpg'
import scrollImg2 from  '../../assets/images/homeImages/wonderland.jpg'
import scrollImg3 from  '../../assets/images/homeImages/pool.jpg'
import scrollImg4 from '../../assets/images/homeImages/sunnycove2.jpg'

const  SideImages = () => {
    return (
        <div className="side-images">
            <div className="img-container">
                <img src={scrollImg1} alt='Kelly Griffiths | Sun Valley' />
                <h1>Sun Valley</h1>
            </div>
            <div className="img-container">
                <img src={scrollImg2} alt='Kelly Griffiths | Laurel Canyon' />
                <h1>Laurel Canyon</h1>
            </div>
            <div className="img-container">
                <img src={scrollImg3} alt='Kelly Griffiths | Laural Canyon' />
                <h1>Laurel Canyon</h1>
            </div>
            <div className="img-container">
                <img src={scrollImg4} alt='Kelly Griffiths | Hollywood Hills' />
                <h1>Hollywood Hills</h1>
            </div>
        </div>
    )
}

export default SideImages