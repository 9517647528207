import React from 'react'
import '../../sass/_slider.scss'

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import pic1 from '../../assets/images/sliderImages/image1.jpg'
import pic2 from '../../assets/images/sliderImages/image2.jpg'
import pic3 from '../../assets/images/homeImages/milwaukee.jpg'
import pic4 from '../../assets/images/sliderImages/image4.jpg'

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
      bullets={false}
    >
      <div className="slider-slides" data-src={pic2}>
        <h1>Montecito Heights</h1>
      </div>
      <div className="slider-slides" data-src={pic1}>
        <h1>Downtown LA</h1>
      </div>
      <div className="slider-slides" data-src={pic3}>
        <h1>Highland Park</h1>
      </div>
      <div className="slider-slides" data-src={pic4}>
        <h1>Highland Park</h1>
      </div>
    </AutoplaySlider>
  );

}

export default Slider