import React from 'react'
import '../../sass/_current-listing.scss'

import house2 from '../../assets/images/homeImages/bathroom.jpg'

const CurrentListing = () => {
    return (
        <div className="current-listings">
            <h1>Current Listings</h1>
            <div className="current">
                <div className="current__house">
                    <div className="current__image">
                        <img src={house2} alt="test" />
                    </div>
                    <div className="current__info">
                        <div className="current__info-address">
                            <p>5 pee 1 doo doo Road</p>
                            <p>Los Angeles, CA</p>
                        </div>
                        <hr />
                        <div className="current__info-rooms">
                            <p>$1,000,000,000</p>
                        </div>
                        <hr />
                        <div className="current__info-price">
                            <p>3 bedrooms | 2 bathrooms | 5,200SF</p>
                        </div>
                    </div>
                </div>
                <div className="current__house">
                    <div className="current__image">
                        <img src={house2} alt="test" />
                    </div>
                    <div className="current__info">
                        <div className="current__info-address">
                            <p>5 pee 1 doo doo Road</p>
                            <p>Los Angeles, CA</p>
                        </div>
                        <hr />
                        <div className="current__info-rooms">
                            <p>$1,000,000,000</p>
                        </div>
                        <hr />
                        <div className="current__info-price">
                            <p>3 bedrooms | 2 bathrooms | 5,200SF</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentListing