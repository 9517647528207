import React from 'react'

export const testimonials = [
    {
        quote: <p className="quote">I couldn't have asked for a better agent</p>,
        blurb:  <p className="blurb">As a first time homebuyer, I was somewhat intimidated by the process but I couldn't have asked for a better agent to help me learn about the market, neighborhood trends, and the ins and outs of a process that I was completely unfamiliar with. Not only is Kelly extremely knowledgeable about Los  Angeles (especially the Eastside, where most of my search was centered), but she's a great second set of eyes and sounding board for advice - both about complicated real estate stuff and general matters of taste (i.e., "Is it just me or did that kitchen kinda suck?" "It's not just you.”) She was extremely accommodating and generous with her weekend schedule to see Open Houses, and I never felt like I was being "sold to" - I was slow and deliberate in my home search and Kelly was never pushy about it.</p>,
        author: <p className="author">- David B.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">Kelly was an absolute dream realtor.</p>,
        blurb: <p className="blurb">Kelly was an absolute dream realtor. We didn't know much about buying a house, but she listened to our needs, showed us amazing houses every weekend, and walked us through the complex business of buying.We ended up in a great house in a neighborhood where the property values have been  exploding!</p>,
        author: <p className="author">- Phil R.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">I would work with Kelly again in a heartbeat.</p>,
        blurb: <p className="blurb">Kelly was my real-estate therapist. LA real estate is crazy, and she walked us through it step-by-step and held my little hand the whole way. My husband and I wanted something unique, and she got that right away. And, once we were in escrow, she made sure the process went smoothly. I would work  with Kelly again in a heartbeat.</p>,
        author: <p className="author">- Amelia G.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">We really felt like we had someone fighting for us.</p>,
        blurb: <p className="blurb">My husband and I were pretty nervous about buying our first house, so I consider myself incredibly lucky to have met our realtor, Kelly Griffiths. Not only was she open, communicative, honest and available, but she was on our side 100%. We really felt like we had someone fighting for us, and she  not only found us our dream home but negotiated us a good price. Three years later, we're still happy in our house, and I recommend Kelly to anyone I know who's looking for a home!</p>,
        author: <p className="author">- Lindsey K.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">She works well with other agents and is well respected by everyone.</p>,
        blurb: <p className="blurb">We're worked with Kelly since moving to LA in 2013 and she's our go-to agent from buying and selling, renting, and even just getting insights on the market. She's very responsive, honest, professional, and detail-oriented. Her negotiation skill is impeccable - we've bought and sold two houses in  the span of five years while working with Kelly. She works well with other agents and is well respected by everyone. You'll be in good hands!</p>,
        author: <p className="author">- Howard Y.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">She's a consummate professional with a wealth of knowledge about the Los Angeles area.</p>,
        blurb: <p className="blurb">I've worked with Kelly Griffiths on a number of real estate transactions over the past 5 years. She's a consummate professional with a wealth of knowledge about the Los Angeles area, has an eye for great opportunities, and has the negotiating experience to guide even the most experienced  residential purchaser through the acquisition of property in Los Angeles.</p>,
        author: <p className="author">- Alex K.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">Kelly's excellence and dependability in every aspect of selling and buying was promising.</p>,
        blurb: <p className="blurb">Kelly answered my highest prayers. Her diligence and ever-present enthusiasm provided me the huge opportunity to buy my perfect home at the perfect price at the right moment! I love my new home and do not plan on moving; but, if I ever do, I will be calling Kelly. Kelly's excellence and dependability in every aspect of selling and buying was promising ~ she is the best!</p>,
        author: <p className="author">- Patty F.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">She is knowledgeable, approachable, personable, endlessly patient.</p>,
        blurb: <p className="blurb">My husband and I worked with Kelly to purchase our first home.  We were interested in a condo and our search began in West LA and eventually ended in Sherman Oaks.  She is knowledgeable, approachable, personable, endlessly patient (lots of first-time buyer questions), and incredibly generous with  her time (we could only do weekends over the course of several weeks). We faced a few challenges during the escrow process, but she answered every question and explained every form clearly and thoroughly.  We definitely could not have done this without Kelly. She took great care of us during the hunting, negotiation, closing, and even after closing. Highly recommend!</p>,
        author: <p className="author">- Inna G</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote"> Her work ethic and professionalism throughout the process were absolutely top notch.</p>,
        blurb: <p className="blurb">As a first time seller with limited time, Kelly did an amazing job getting my loft staged and ready for the market. This resulted in receiving multiple offers north of the asking price within three days of the property being listed. Then, once we had an offer in hand, she did a truly outstanding job working with a variety of contractors as well as the building's HOA in order to get some needed work finished in time for the closing date. Her work ethic and professionalism throughout the process were absolutely top notch. If you are interested in real estate in Downtown Los Angeles, please look no further than Kelly whom I recommend completely and without reservation. She's the best!</p>,
        author: <p className="author">- Chris L.</p>,
        class: "active"

    },
    {
        quote: <p className="quote">Kelly simplified the relocation and significantly reduced the stress.</p>,
        blurb: <p className="blurb">We were relocating from the UK to LA, with 3 children and 3 pets, obviously presenting a huge challenge to find a suitable property in the best school district without a punishing commute. Despite these hurdles Kelly was able to present to us many suitable properties whilst at all times remaining incredibly patient with our changing demands. Kelly simplified the relocation and significantly reduced the stress associated with the move.</p>,
        author: <p className="author">- Chris H.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">She completely matched my needs.</p>,
        blurb: <p className="blurb">Kelly found me a wonderful apartment to rent when I came to LA to work on a series for NBC. She very quickly drew up a shortlist of great properties to see, but we never had to look beyond the first apartment she took us to, because it was in fact truly perfect - she had completely matched my needs. In fact, I loved living there so much, that I rented it again when I subsequently returned to Los Angeles.</p>,
        author: <p className="author">- Anthony Stewart Head</p>,
        class: "active"
    },
    {
        quote: <p className="quote">She was always very responsive which made us feel like her most important client.</p>,
        blurb: <p className="blurb">As first time home buyers, we didn't know much about the process and had heard horror stories from our friends about missing great houses due to unresponsive agents. Kelly was nothing like those we had heard about. She was always very responsive which made us feel like her most important client and she had wonderful advice every step of the way. She made the experience nearly stress free, and dare I say, enjoyable. I recommend Kelly to all my friends.</p>,
        author: <p className="author">- Leah W.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">She helped me find a lovely apartment, in the perfect location, within a week.</p>,
        blurb: <p className="blurb">I called Kelly Griffiths to see if she had any ideas of where I could find a short term rental in LA. She helped me find a lovely apartment, in the perfect location, within a week of me calling her. It was everything I was looking for, and more. Not only was she a great help, she also became a great friend.</p>,
        author: <p className="author">- Emily H.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">Kelly made what should be an intimidating purchase FUN.</p>,
        blurb: <p className="blurb">As first time home buyers, Kelly couldn't have made our experience easier. She made everything about the process crystal clear, and was amazingly professional, available, and attentive to absolutely all of our needs. Kelly made what should be an intimidating purchase FUN...which kind of blew our minds!</p>,
        author: <p className="author">- Brad D.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">She never put pressure on me and was always on the lookout for something that might fit.</p>,
        blurb: <p className="blurb">I cannot say enough good things about Kelly! It took me over six months and 200+ properties to find my house! She never put pressure on me and was always on the lookout for something that might fit. She is a true professional and puts her clients first!</p>,
        author: <p className="author">- Brittani T.</p>,
        class: "active"
    },
    {
        quote: <p className="quote"> She took a strategic approach to the purchase based on my financial situation and preferences.</p>,
        blurb: <p className="blurb">For a first time home buyer I had no idea what to expect. Kelly walked me through every step of the process which I thought would be intimidating, but she did so in such a way where I actually enjoyed learning and participating in the experience. She took a strategic approach to the purchase based on my financial situation and preferences which resulted in my dream home. I plan on using her again when the need arises and would highly recommend her.</p>,
        author: <p className="author">- Chuck D.</p>,
        class: "active-2"
    },
    {
        quote: <p className="quote">She helped find the perfect home for my family, in an extremely competitive market.</p>,
        blurb: <p className="blurb">Kelly exhibits the highest level of excellence and professionalism in her field.  She helped find the perfect home for my family, in an extremely competitive market.  I honestly do not know what we would have done with out her.  She was our right hand women, helping us every step of the way: setting up inspections with specialist, communicating with the sellers agent, and above all looking out for our best interest.   Kelly is honest, knowledgeable, responsive, and on top of everything.  I can not say enough good things about Kelly and my husband and I feel very lucky to have had her representing us.</p>,
        author: <p className="author">- Brittney O.</p>,
        class: "active"
    },
    {
        quote: <p className="quote">She didn't push us into anything over our budget or out of our comfort zone.</p>,
        blurb: <p className="blurb">Kelly has that personal touch. She made sure she found out all of our needs and desires and amended them over time, which allowed her to refine the weekly list of houses she sent us to perfection. It took the pressure off us immensely and meant that we found our home in under a month! She didn't push us into anything over our budget or out of our comfort zone and throughout escrow, her diligence ensured that we knew exactly what was happening every step of the way. I will have zero hesitation in recommending Kelly to my closest friends.</p>,
        author: <p className="author">- Raziq R.</p>,
        class: "active-2"
    },
]