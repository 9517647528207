import React from 'react'
import '../../sass/_footer.scss'

import compassLogo from '../../assets/images/compass/compass-logo-white.png'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__content">
                <div className="footer__info">
                    <p>DRE#01887730</p>
                    <div>|</div>
                    <p><a href="mailto:Kelly@LAKelly.com" rel="noopener noreferrer">Kelly@LAKelly.com</a></p> 
                    <div>|</div>
                    <p><a href="tel:+1 (310) 971 1030" rel="noopener noreferrer">+1(310) 971 1030</a></p>
                </div>
                <div className="footer__logo">
                    <a href="https://www.compass.com/agents/kelly-griffiths/" target="_blank" rel="noopener noreferrer"><img src={compassLogo} alt="Kelly Griffiths | Compass" /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer