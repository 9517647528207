import React, { useState } from 'react'

import '../../sass/_secondaryNav.scss'
import '../../sass/_nav.scss'

import { Link } from 'react-router-dom'

const SecondaryNav = () => {
    const [ isOpen, setIsOpen ] = useState(false)

    window.onresize = () => (window.innerWidth > 700 && isOpen) && setIsOpen(false)
    
    return (
        <>
            <nav className='scrolled'>
                <div className="nav__logo">
                    <h1><Link to="/">Kelly Griffiths</Link></h1>
                </div>
                <div className="nav__list">
                    <ul>
                        <li><Link to="/recently-sold">Recently Sold</Link></li>
                        <li><Link to="/testimonials">Testimonials</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div onClick={() => setIsOpen(!isOpen)} className={`hamburger ${isOpen ? 'open' : 'closed'}`}>
                    <div className='hamburger__bar-scroll' />
                    <div className='hamburger__bar-scroll' />
                    <div className='hamburger__bar-scroll' />
                </div>
                <div className={`overlay ${isOpen ? 'show' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <div className="overlay__list">
                        <ul>
                            <li><Link to="/recently-sold">Recently Sold</Link></li>
                            <li><Link to="/testimonials">Testimonials</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default SecondaryNav