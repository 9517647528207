import React, { useState } from 'react'
import '../../sass/_nav.scss'

import { Link} from 'react-router-dom'

const Nav = () => {
    const [ isOpen, setIsOpen ] = useState(false)
    const [ isTop, setIsTop ] = useState(false)

    window.onresize = () => (window.innerWidth > 700 && isOpen) && setIsOpen(false)

    const navScroll = () => {
        if (window.innerWidth > 700) {
            window.scrollY > 300 ? setIsTop(true) : setIsTop(false)
        } else {
            window.scrollY > 150 ? setIsTop(true) : setIsTop(false)
        }
    }

    window.addEventListener('scroll', navScroll)
    
    return (
        <>
            <nav className={`${isTop ? 'scrolled' : 'nav'}`}>
                <div className="nav__logo">
                    <h1><Link to="/">Kelly Griffiths</Link></h1>
                </div>
                <div className="nav__list">
                    <ul>
                        <li><Link to="/recently-sold">Recently Sold</Link></li>
                        <li><Link to="/testimonials">Testimonials</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div onClick={() => setIsOpen(!isOpen)} className={`hamburger ${isOpen ? 'open' : 'closed'}`}>
                    <div className={`${isTop ? 'hamburger__bar-scroll' : 'hamburger__bar'}`} />
                    <div className={`${isTop ? 'hamburger__bar-scroll' : 'hamburger__bar'}`} />
                    <div className={`${isTop ? 'hamburger__bar-scroll' : 'hamburger__bar'}`} />
                </div>
                <div className={`overlay ${isOpen ? 'show' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <div className="overlay__list">
                        <ul>
                            <li><Link to="/recently-sold">Recently Sold</Link></li>
                            <li><Link to="/testimonials">Testimonials</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Nav